<template>
  <div v-if="!editMode">
    <b-row class="mt-3">
      <b-col class="gray-col-title" :cols="getCols(3)">
        {{ $t('views.top-bar.settings.general-informations.contacts.fixPhoneNumber') }}
      </b-col>
      <b-col class="black-col-title">
        {{ club.fixPhoneNumber == null ? $t('general.actions.not-specified') : club.fixPhoneNumber }}
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="gray-col-title" :cols="getCols(3)">
        {{ $t('views.top-bar.settings.general-informations.contacts.mobilePhoneNumber') }}
      </b-col>
      <b-col class="black-col-title">
        {{ club.mobilePhoneNumber == null ? $t('general.actions.not-specified') : club.mobilePhoneNumber }}
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="gray-col-title" :cols="getCols(3)">
        {{ $t('views.top-bar.settings.general-informations.contacts.email') }}
      </b-col>
      <b-col class="black-col-title">
        {{ club.email == null ? $t('general.actions.not-specified') : club.email }}
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="gray-col-title" :cols="getCols(3)">
        {{ $t('views.top-bar.settings.general-informations.contacts.webSiteUrl') }}
      </b-col>
      <b-col class="black-col-title">
        <div v-if="club.webSiteUrl == null || club.webSiteUrl.trim() === ''">
         {{ $t('general.actions.not-specified') }}
        </div>
        <a
          v-else
          class="link-color"
          @click="goToLink(club.webSiteUrl)"
        >
          {{ club.webSiteUrl }}
        </a>
      </b-col>
    </b-row>
    <div v-if="club.socialNetworks">
      <b-row v-if="club.socialNetworks.facebook" class="mt-3">
        <b-col :cols="getCols(1)">
          <div class="social">
            <i class="fa fa-facebook"></i>
          </div>
        </b-col>
        <b-col class="mt-1 black-col-title">
          <a
            class="link-color"
            @click="goToLink(club.socialNetworks.facebook)"
          >
            {{ club.socialNetworks.facebook }}
          </a>
        </b-col>
      </b-row>
      <b-row v-if="club.socialNetworks.instagram" class="mt-3">
        <b-col :cols="getCols(1)">
          <div class="social">
            <i class="fa fa-instagram"></i>
          </div>
        </b-col>
        <b-col class="mt-1 black-col-title">
          <a
            class="link-color"
            @click="goToLink(club.socialNetworks.instagram)"
          >
            {{ club.socialNetworks.instagram }}
          </a>
        </b-col>
      </b-row>
      <b-row v-if="club.socialNetworks.twitter" class="mt-3">
        <b-col :cols="getCols(1)">
          <div class="social">
            <i class="fa fa-twitter"></i>
          </div>
        </b-col>
        <b-col class="mt-1 black-col-title">
          <a
            class="link-color"
            @click="goToLink(club.socialNetworks.twitter)"
          >
            {{ club.socialNetworks.twitter }}
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
  <div v-else>
    <b-row class="mt-3">
      <b-col class="gray-col-title mt-1" :cols="getCols(3)">
        {{ $t('views.top-bar.settings.general-informations.contacts.fixPhoneNumber') }}
      </b-col>
      <b-col class="black-col-title">
        <d-phone-number
            v-model="club.fixPhoneNumber"
            :rules="validation.fixPhoneNumber"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="gray-col-title mt-1" :cols="getCols(3)">
        {{ $t('views.top-bar.settings.general-informations.contacts.mobilePhoneNumber') }}
      </b-col>
      <b-col class="black-col-title">
        <d-phone-number
            v-model="club.mobilePhoneNumber"
            :rules="validation.mobilePhoneNumber"
            size="sm"
            class-name="background-light-blue-inputs"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="gray-col-title mt-1" :cols="getCols(3)">
        {{ $t('views.top-bar.settings.general-informations.contacts.email') }}
      </b-col>
      <b-col class="black-col-title">
        <d-text-field
            v-model="club.email"
            :rules="validation.email"
            size="sm"
            place-holder="general.actions.not-specified"
            class-name="background-light-blue-inputs"
            error-text="validation.email"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col class="gray-col-title" :cols="getCols(3)">
        {{ $t('views.top-bar.settings.general-informations.contacts.webSiteUrl') }}
      </b-col>
      <b-col class="black-col-title">
        <d-text-field
            v-model="club.webSiteUrl"
            :rules="validation.webSiteUrl"
            size="sm"
            place-holder="general.actions.not-specified"
            class-name="background-light-blue-inputs"
        />
      </b-col>
    </b-row>
    <div>
      <b-row class="mt-3">
        <b-col :cols="getCols(1)">
          <div class="social">
            <i class="fa fa-facebook"></i>
          </div>
        </b-col>
        <b-col class="mt-1 black-col-title">
          <d-text-field
              v-model="club.socialNetworks.facebook"
              :rules="validation.webSiteUrl"
              size="sm"
              place-holder="general.actions.not-specified"
              class-name="background-light-blue-inputs"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col :cols="getCols(1)">
          <div class="social">
            <i class="fa fa-instagram"></i>
          </div>
        </b-col>
        <b-col class="mt-1 black-col-title">
          <d-text-field
              v-model="club.socialNetworks.instagram"
              :rules="validation.webSiteUrl"
              size="sm"
              place-holder="general.actions.not-specified"
              class-name="background-light-blue-inputs"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col :cols="getCols(1)">
          <div class="social">
            <i class="fa fa-twitter"></i>
          </div>
        </b-col>
        <b-col class="mt-1 black-col-title">
          <d-text-field
              v-model="club.socialNetworks.twitter"
              :rules="validation.webSiteUrl"
              size="sm"
              place-holder="general.actions.not-specified"
              class-name="background-light-blue-inputs"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      club: {
        type: Object,
        default: () => {},
      },
      validation: {
        type: Object,
        default: () => require('@validation/entities/doinsport/Club.json'),
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      cols: {
        type: String,
        default: '6',
      }
    },
    methods: {
      goToLink(link) {
        window.open(link, '_blank');
      },
      getCols(x) {
        const width = this.$store.getters["layout/getInnerWidth"];
        if (this.cols === '12') {
          if (width < 767) {
            return 12;
          }
          return '6';
        }
        if (width < 1967) {
          if (x === 1) {
            return 2;
          } else if (x === 2) {
            return 3
          } else if (x === 3) {
            return 4;
          }
        } else {
          return x;
        }
      }
    },
  }
</script>
<style scoped lang="scss">
.link-color {
  color: #0B2772;
  cursor: pointer;
}
</style>
